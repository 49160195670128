<!-- 编辑弹窗 -->
<template>
  <a-modal
    :width="680"
    :visible="visible"
    :confirm-loading="loading"
    :title="isUpdate ? '修改类别' : '新建类别'"
    :body-style="{ paddingBottom: '8px' }"
    @update:visible="updateVisible"
    @ok="save"
  >
    <a-form
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ md: { span: 5 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 19 }, sm: { span: 24 } }"
    >
      <a-form-item label="类别名称:" name="categoryName">
        <a-input
          v-model:value="form.categoryName"
          placeholder="请输入类别名称"
          allow-clear
        />
      </a-form-item>
      <a-form-item label="排序:" name="sortNumber">
        <a-input-number v-model:value="form.sortNumber" :min="0" :step="1" />
      </a-form-item>
      <a-form-item label="备注:" name="comments">
        <a-textarea
          :rows="4"
          :maxlength="500"
          placeholder="请输入备注"
          v-model:value="form.comments"
          allow-clear
        ></a-textarea>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import * as categoryApi from '@/api/cms/category'
export default {
  name: 'GroupEdit',
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data, {
        groupName: ''
      }),
      // 编辑弹窗表单验证规则
      rules: {
        categoryName: [
          {
            required: true,
            message: '请输入类别名称',
            type: 'string',
            trigger: 'blur'
          }
        ]
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      modules: []
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.form = this.data
        this.isUpdate = true
      } else {
        this.form = {}
        this.isUpdate = false
      }
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
    }
  },
  mounted() {},
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true
          categoryApi
            .save(this.form, this.isUpdate)
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                this.$message.success(res.msg)
                if (!this.isUpdate) {
                  this.form = {}
                }
                this.updateVisible(false)
                this.$emit('done')
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => {})
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>
